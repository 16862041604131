<template>
  <b-card class="border-0" no-body>
    <template #header>
      <div class="d-flex">
        <strong class="flex-grow-1">{{ title }}</strong>
        <b-button variant="link" @click="show = !show">
          <b-icon :icon="'chevron-double-' + (show ? 'up' : 'down')" />
        </b-button>
      </div>
    </template>
    <b-collapse v-model="show">
      <div class="row">
        <div class="col"><member-details /></div>
        <div class="col"><training-center-details /></div>
      </div>
    </b-collapse>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-list-group flush>
            <b-list-group-item>
              <p>
                Easily access your TVC by clicking on the
                <b-link :href="tvcLink" target="_blank">My Training Verification Card</b-link>
                link next to your photo.
              </p>
              <p>Download your Training History.</p>
              <p>Your local Training Center contact information is displayed on the top right of this page.</p>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <strong>Membership</strong>
      </b-card-header>
      <b-list-group flush>
        <b-list-group-item>
          <p>
            The Membership module allows you to view your contact information and other membership related information shared by the Training Fund.
            Please contact your Training Center for additional information.
          </p>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <strong>Apprenticeship <span class="text-muted">(for Apprentices only)</span></strong>
      </b-card-header>
      <b-list-group flush>
        <b-list-group-item>
          <p>
            The Apprenticeship module allows you to view your information related to your status in the apprenticeship program and upgrade information
            shared by the Training Fund. Please contact your Training Center for additional information.
          </p>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <strong>Training</strong>
      </b-card-header>
      <b-list-group flush>
        <b-list-group-item>
          <p><strong>Class Registration</strong></p>
          <p>
            The Member Class Registration module allows you to submit a request to be registered for available classes at your local training center.
            Once you submit a request, the training center will take the appropriate action. Submitting a request does not guarantee registration.
          </p>
        </b-list-group-item>
        <b-list-group-item>
          <p><strong>My Training History</strong></p>
          <p>The Member Training History module allows you to view your non-expired qualifications and certifications.</p>
        </b-list-group-item>
        <b-list-group-item>
          <p>
            <strong>Required Curriculum <span class="text-muted">(for Apprentices only)</span></strong>
          </p>
          <p>
            In this module, track the classes you must attend to complete the apprenticeship program. Only the Class Name will populate until you have
            been scheduled in a class. Please contact your Training Center for additional information about your curriculum.
          </p>
        </b-list-group-item>
        <b-list-group-item>
          <p>For more information, click <b-link href="/docs/Training_Quick_Reference_Guide.pdf" target="_blank">here</b-link>.</p>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <strong>Time Card <span class="text-muted">(for Apprentices only)</span></strong>
      </b-card-header>
      <b-list-group flush>
        <b-list-group-item>
          <p>
            When enabled by the Training Fund, the Time Card feature allows you to record On‐the-Job Training (OJT) work hours, view, update, and delete
            time entries, and export a Work Hours Report. Each Training Fund sets limits on work hour submissions. Please contact your Training Center
            for additional information about time cards.
          </p>
        </b-list-group-item>
        <b-list-group-item>
          <p>For more information, click <b-link href="/docs/Time_Card_Quick_Reference_Guide.pdf" target="_blank">here</b-link>.</p>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-card>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import MemberDetails from '@/views/details/MemberDetails';
import TrainingCenterDetails from '@/views/details/TrainingCenterDetails';

@Component({
  components: {
    MemberDetails,
    TrainingCenterDetails
  },
  metaInfo: {
    title: 'About Member Portal'
  }
})
export default class About extends Vue {
  show = true;
  get member() {
    return this.$store.getters.currentMember || {};
  }

  get title() {
    return this.$store.getters.title;
  }

  get tvcLink() {
    return this.$store.getters.tvcLink;
  }
}
</script>
<style></style>
